@import '~antd/dist/antd.css';
@import './theme/index.scss';

#root {
  height: 100%;
  overflow: auto;
  background-color: #f8f8f8;
  font-size: 14px;
  color: #333;

  // 默认样式
  p {
    margin-bottom: 0;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }
}
