.up {
	width: 90px;
	height: 90px;
	position: fixed;
	bottom: 100px;
	right: 100px;
	z-index: 3;
	display: block;
	cursor: pointer;

	.up-img {
		width: 100%;
		object-fit: cover;
	}
}