@font-face {
	font-family: 'iconfont';  /* project id 2518154 */
	src: url('//at.alicdn.com/t/font_2518154_iwbmluvjuur.eot');
	src: url('//at.alicdn.com/t/font_2518154_iwbmluvjuur.eot?#iefix') format('embedded-opentype'),
	url('//at.alicdn.com/t/font_2518154_iwbmluvjuur.woff2') format('woff2'),
	url('//at.alicdn.com/t/font_2518154_iwbmluvjuur.woff') format('woff'),
	url('//at.alicdn.com/t/font_2518154_iwbmluvjuur.ttf') format('truetype'),
	url('//at.alicdn.com/t/font_2518154_iwbmluvjuur.svg#iconfont') format('svg');
}

